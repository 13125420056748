<template>
	<div class="login-box">
		<div class="main-box">
			<div class="login-input" @keyup.enter="theLogin">
				<div class="left-box">
					<img class="logo" src="../assets/images/jiaobo.png" />
					<!-- <img class="imgs" src="../assets/images/imgs.png"/> -->
				</div>
				<div class="right-box">
					<div class="title-box">
						<p class="title">L20-N++</p>
						<p class="subtitle">爆破安全监测云平台</p>
					</div>
					<div class="input-cell">
						<img class="account" src="../assets/images/account.png" />
						<el-input v-model="login.account" placeholder="请输入用户名"></el-input>
					</div>
					<div class="input-cell">
						<img class="password" src="../assets/images/password.png" />
						<el-input type="password" v-model="login.password" show-password placeholder="请输入密码"></el-input>
					</div>
					<div class="but-cell"><img class="pointer" src="../assets/images/but.png" @click="theLogin" /></div>
				</div>
			</div>
			<div class="footer-box">
				<div class="content-box">
					<img class="QR" src="../assets/images/QR.png" />
					<div class="text-box">
						<p class="text-p one-text">四川交博环境检测有限公司</p>
						<p class="text-p">
							<span class="name">技术支持</span>
							：成都交博科技有限公司
						</p>
						<p class="text-p">
							<span class="name">备案号</span>
							：
							<a style="transform: translateX(-4px);display: inline-block;" href="https://beian.miit.gov.cn/#/Integrated/index">蜀ICP备16021963号-3</a>
						</p>
						<p class="text-p">
							<span class="name">联系电话</span>
							：028-87789088&nbsp;/&nbsp;18408214363
						</p>
						<p class="text-p">
							<span class="name">联系地址</span>
							：四川省成都市金牛区二环路北一段10号万科V派商务楼15楼17号
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {
			login: {
				account: '',
				password: ''
			} //账户密码
		};
	},
	created() {
		localStorage.setItem('first', 0);
	},
	mounted() {},
	computed: {},
	methods: {
		// 登录
		theLogin() {
			if (this.login.account == '') {
				this.$message({
					showClose: true,
					message: '请输入账户！',
					type: 'warning'
				});
				return;
			} else if (this.login.password == '') {
				this.$message({
					showClose: true,
					message: '请输入密码！',
					type: 'warning'
				});
				return;
			}
			let data = {
				username: this.login.account,
				password: this.login.password,
				'remember-me': 1,
				t: new Date()
			};
			this.axios.post('login', data).then(res => {
				if (res.status) {
					// return;
					localStorage.setItem('lInfo', res.data.head);
					localStorage.setItem('data', JSON.stringify(res.data));
					localStorage.setItem('userName', res.data.name);
					// 清除页面记录的接口遇到401重新请求的次数
					sessionStorage.clear();
					this.toRouter('index');
				}
			});
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.login-box {
	width: 100%;
	height: 100%;
	position: relative;
	background: url('../assets/images/loginb.jpg') no-repeat;
	background-size: 100% 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.main-box {
		// width: 500px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 7px;

		.login-input {
			height: 465px;
			display: flex;
			align-items: center;
			border: 1px solid #fff;
			margin-bottom: 20%;

			.left-box {
				width: 590px;
				height: 100%;
				background: url('../assets/images/imgs-box.jpg') no-repeat;
				background-size: 100% 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;

				.logo {
					width: 360px;
					height: auto;
					object-fit: scale-down;
					margin-top: 75px;
				}

				.imgs {
					width: 590px;
					height: auto;
					object-fit: scale-down;
				}
			}

			.right-box {
				width: 385px;
				height: 100%;
				background: url('../assets/images/input-box.jpg') no-repeat;
				background-size: 100% 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.title-box {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					.title {
						font-size: 22px;
						font-weight: 600;
						color: #fff;
					}

					.subtitle {
						font-size: 18px;
						color: #fff;
					}
				}

				.input-cell {
					width: 288px;
					display: flex;
					justify-content: space-around;
					align-items: center;
					margin: 0 auto;
					margin-top: 35px;
					background-color: #d2d5d8;
					border: 1px solid #ebeef5;
					border-radius: 2px;

					.account {
						width: 15px;
						height: auto;
						object-fit: scale-down;
						margin-left: 6px;
					}

					.password {
						width: 13px;
						height: auto;
						object-fit: scale-down;
						margin-left: 5px;
					}

					::v-deep {
						.el-input {
							width: calc(100% - 25px);
							background: none;

							.el-input__inner {
								border: none !important;
								background: none;
								height: 32px;
								padding: 0 15px 0 8px;
							}

							.el-input__icon {
								line-height: 32px;
								color: #000;
							}
						}
					}

					.c-icon {
						font-size: 22px;
					}
				}

				.but-cell {
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						width: 314px;
						height: auto;
						object-fit: scale-down;
						margin-top: 35px;
					}
				}
			}
		}

		.footer-box {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			bottom: 50px;
			left: 0;
			right: 0;
			margin: auto;

			.content-box {
				display: flex;
				align-items: center;

				.QR {
					// width: 103px;
					// height: auto;
					// object-fit: scale-down;
					width: auto;
					height: 107px;
					margin-right: 15px;
					flex-shrink: 0;
				}

				.text-box {
					.text-p {
						font-size: 14px;
						color: #4e5c71;
						margin-top: 3px;

						.name {
							width: 58px;
							display: inline-block;
							text-align: justify;
							text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
							text-align-last: justify;
						}
					}

					.one-text {
						font-size: 17px;
						color: #000;
						font-weight: 600;
						margin-top: 0;
						letter-spacing: 0.8px;
					}
				}
			}
		}
	}
}
</style>
